import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "voice-and-tone"
    }}>{`Voice and Tone`}</h1>
    <h2 {...{
      "id": "voice"
    }}>{`Voice`}</h2>
    <p>{`Voice is the undercurrent of all writing that appears in our product. We sound smart and sharp, but
never pretentious or too technical. We value being accessible and friendly for seasoned users and
newbies alike. Our audience wants to use our product effortlessly, without reading language that’s
too formal or unnatural. So we speak to our users like a friend.`}</p>
    <blockquote>
      <p parentName="blockquote">{`As users, “we try to create a coherent picture of the personality communicating with us. If we
cannot create such a picture, we become suspicious, and feel a sense of rejection."`}<br parentName="p"></br>{`
`}{`-`}{` Kinneret Yifrah, “Microcopy: The Complete Guide”`}</p>
    </blockquote>
    <h2 {...{
      "id": "tone"
    }}>{`Tone`}</h2>
    <p>{`Tone is determined by time and place. A wedding invitation will speak differently than a termination
letter. In Workday, an error message varies greatly from a success notification. So for each
interaction, we pay close attention to these factors: what are we trying to communicate? Are we
celebrating success or empathizing with frustration or failure? Or are we simply relaying
information clearly and concisely?`}</p>
    <p>{`No matter what our tone is, remember that our voice always remains the same. At the center of
everything, our writing is always helpful, and we embrace clarity every time.`}</p>
    <h2 {...{
      "id": "voice-and-tone-tips"
    }}>{`Voice and Tone Tips`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Try saying it out loud to a friend.`}</strong>{` Is there a simpler, more accessible way to say it without
jargon or tech-specific words? Can you cut any words, or be more specific?`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Sound like a human.`}</strong>{` Just because you’ve seen technical-sounding language in other software
doesn’t mean you need to write that way. How can you make your writing sound like a person, not a
system, is saying it?`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Use terminology that everyone can understand.`}</strong>{` Your audience shouldn’t have to ask “what do
they mean by `}{`_`}{`_`}{`_`}{`?”. Try asking an uninvolved colleague to read your copy to see if they
understand it.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Remember our global audience.`}</strong>{` Write in a way that's approachable to users all over the world.
Try to avoid idioms, and use clear, simple vocabulary.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Consider the flow.`}</strong>{` What happens before and what happens after your user reads the text? If
they have another step in their process, a button that says “Next” or “Continue” makes more sense
than a button that says “Done.”`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "voice-and-tone-example"
    }}>{`Voice and Tone Example`}</h2>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`This report shows total compensation costs for employees and contingent workers.`}</li>
      </ul>
    </Do>
    <Dont mdxType="Dont">
      <ul>
        <li parentName="ul">{`Accesses the Workday Account object and returns one row per Workday account. Includes all Workday
accounts ever created, either currently enabled or not. Does not contain built-in prompts. This
data source will show settings of a user’s login information and preferences in Workday.`}</li>
      </ul>
    </Dont>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      